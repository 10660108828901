:root {
  --blue: #428bca;
  --color-secondary-text: #53565a;
}

html,
body {
  height: 100%;
}

body {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

a {
  color: #0f5897;
}

h3,
.card-title.h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
}

#app {
  min-height: 100vh;
  height: 100%;
  padding: 16px;
}

.label,
label {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
}

.label-body {
  font-weight: normal;
  font-size: 16px;
}

.btn {
  font-size: 14px;
  font-weight: normal;
}

.btn-primary {
  background-color: #425cc7;
  border-color: #425cc7;
  opacity: 1;
}

.btn-primary:hover {
  background-color: #546ccc;
  border-color: #546ccc;
  opacity: 1;
}

.btn-primary:focus {
  background-color: #425cc7;
  border-color: #425cc7;
  opacity: 1;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #2e408a;
  border-color: #2e408a;
  opacity: 1;
}

.btn-primary:disabled,
.btn-primary-disabled {
  background-color: #c6ceee;
  border-color: #c6ceee;
  opacity: 1;
}

.secondary-text {
  color: var(--color-secondary-text);
}

.overview-number {
  width: 22px;
  height: 22px;
  border: 1px solid #6c757d;
  border-radius: 11px;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  padding-top: 1px;
  margin-top: 2px;
}

.overview-time {
  color: var(--color-secondary-text);
  font-size: 14px;
}

#overview .list-group-item {
  border-left-width: 0;
  border-right-width: 0;
}

#overview .list-group-item:first-child,
.list-group-item:last-child {
  border-radius: 0;
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

#overview .list-group-item.active {
  background-color: transparent;
  font-weight: 600;
  color: black;
  border-color: #e0e0e0;
}

.text-hint {
  font-size: 12px;
  color: #4d4d4f;
  font-weight: normal;
  font-style: italic;
}

.form-inline input {
  display: inline;
  /* width: auto; */
}

.panel-no-margin {
  margin: 0;
}

.button-blue {
  background-color: var(--blue);
  border-color: var(--blue);
  color: #fff;
}

label.small-text {
  display: block;
}

.small-text {
  font-size: 13px;
  line-height: 18px;
}

.questionCounter {
  font-size: 12px;
}

.button-block {
  display: block;
  width: 100%;
}

.disabled-button {
  opacity: 0.75;
  pointer-events: none;
}

.button-primary-text {
  font-size: 1em;
  color: #424242;
}

.selectScale {
  margin: 15px 0 0 0;
  padding: 0;
  width: 100%;
}

.selectScale th,
.selectScale td {
  width: 20%;
  text-align: center;
  padding: 0 5px;
}

.selectScaleButtons tr {
  border: 0;
}

.selectScaleButtons tr td {
  vertical-align: top;
  text-align: center;
}

.selectScaleButtons tr td input {
  margin: 0;
  padding: 0;
}

.typingPassage {
  margin: 0 50px;
  padding: 10px 15px;
  font-size: 14px;
  background-color: #f7f7f7;
}

.ten-key-odd-row {
  background-color: #ececec;
}

.ten-key-prompts {
  font-size: 13px;
  line-height: 26px;
  font-family: "Courier New", Courier;
  padding-left: 5px;
}

h4 {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
}

#logo {
  margin-bottom: 25px;
}

#event-id-form-icon {
  color: var(--blue);
  padding-right: 3px;
}

.location-text {
  font-weight: 700;
}

#agreeToTerms {
  font-size: 18px;
  margin-right: 5px;
}

#verify-form-submit-button {
  margin: 10px auto 0px auto;
}

#demographic-questions-container {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 20px;
}

#optional-text {
  margin-top: 5px;
}

#optional-divider {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

#lead {
  font-size: 21px;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 1.4;
}

#begin-assessment-warning {
  color: #9f3b3a;
  font-weight: bold;
}

#tech-support-info {
  padding-top: 20px;
}

#instructions-text {
  min-height: 250px;
}

/*
#instructions-continue-button,
.instructions-button {
  background-color: var(--blue);
  border-color: var(--blue);
}
*/

#timer {
  font-size: 2.5em;
  font-weight: 700;
  text-align: right;
  padding: 10px 0;
  margin-top: -10px;
  font-family: monospace;
  line-height: 1em;
}

#typingAreaContainer {
  margin: 0 50px;
}

#typing-test-textarea {
  margin-top: 2px;
  width: 98%;
  height: 260px;
  font-size: 13px;
  line-height: 24px;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  color: #555;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

#tm-typing-test-textarea-container {
  overflow: hidden;
  height: 76vh;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

#tm-typing-test-textarea {
  /* margin-top: 2px; */
  width: 100%;
  font-size: 13px;
  line-height: 20px;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  color: #555;
  padding: 14px;
}

.tm-typingPassage {
  width: 100%;
  overflow: auto;
  font-size: 14px;
  height: 76vh;
  line-height: 20px;
  padding: 10px 15px;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  background-color: #f7f7f7;
}

#ten-key-textarea {
  margin-top: -2px;
  width: 100%;
  height: 545px;
  font-size: 13px;
  line-height: 26px;
  font-family: "Courier New", Courier;
  overflow: auto;
}

#ten-key-finish-button {
  width: 250px;
  height: 75px;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 16px;
  line-height: 1.33;
  border-radius: 6px;
  background-color: var(--blue);
  color: #fff;
}

#ten-key-finish-button-container {
  margin-top: 250px;
  padding-left: 50px;
}

.test-question {
  font-size: 1.3em;
  margin-bottom: 20px;
}

.small-text {
  font-size: 0.8em;
}

.personality-row h4 {
  padding: 15px 0;
}

.dropdown-btn {
  padding: 0;
  color: "blue";
}

.dropdown-btn:focus {
  outline: 1px solid var(--blue);
  /* Hacky but it allows an outline without weird padding issues with bootstrap and should suffice until rewritten  */
  margin: -0.25rem;
  padding: 0.25rem;
}

@media (min-width: 768px) {
  .personality-row {
    border-bottom: 1px solid #ccc;
  }
}

.personality-label {
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px 0;
}

.personality-label input {
  font-size: 50px;
}

@media (max-width: 767px) {
  .personality-label {
    min-height: 100px;
    border: 1px solid #979797;
    border-radius: 3px;
    padding: 0 3px;
  }
  .personality-row {
    margin-bottom: 5px;
  }
  .selectScale {
    margin: 0;
  }
}

@media (max-width: 375px) {
  .small-text {
    font-size: 0.65em;
  }
}

#select-scale-footer thead tr {
  border-bottom: 0;
}

#select-scale-footer thead tr th {
  vertical-align: top;
  color: #333;
  font-weight: 400;
  width: 20%;
}

#select-scale-header thead tr {
  border-bottom: 0;
}

#select-scale-header thead tr th {
  vertical-align: bottom;
  color: #333;
  font-weight: 400;
  width: 20%;
}

#select-file-button-text {
  margin-left: 10px;
}

#application-header {
  font-size: 24px;
}

.application-error-message {
  color: #a94442;
  font-weight: 600;
}

input[type="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 10px;
  line-height: 20px;
  font-size: 16px;
  font-weight: inherit;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  display: block;
  width: 100%;
}

input[type="email"]:-ms-input-placeholder {
  color: #9e9e9e;
}

input[type="email"]::-ms-input-placeholder {
  color: #9e9e9e;
}

input[type="email"]::placeholder {
  color: #9e9e9e;
}

input[type="email"]:focus {
  outline: none;
  border-color: #4caf50;
}

input[type="email"].invalid {
  border-color: #f44336;
}

#test-content {
  margin-top: -50px;
}

hr {
  border: 0;
  height: 1px;
  background: #ccc;
}

.img-responsive {
  min-height: 100px;
  max-height: calc(100vh - 275px);
  max-width: 100%;
  width: auto;
}

.img-responsive95 {
  min-height: 250px;
}

@media (max-width: 667px) {
  .img-responsive {
    min-width: 100px;
    max-width: 320px;
    height: auto;
    max-height: auto;
  }
  .img-responsive95 {
    min-height: auto;
  }
}

.mc-answer {
  margin-bottom: 5px;
}

.upload-resume-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

/* Bandaid fix until re-written  - not willing to poke to hard at the uploader before then */
.upload-resume-btn-wrapper:focus-within {
  outline: 1px solid var(--blue);
  border-radius: 4px;
}

.upload-resume-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.tmAnswers tr {
  border: 0;
}

.tmAnswers td {
  vertical-align: top;
  padding: 5px;
}

.tmAnswerSelection {
  width: 20px;
}

/* Game Test CSS */

.app,
.container {
  padding: 0;
}

#game-test {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: calc(100vh - 115px);
  /* height: calc((var(--vh, 1vh) * 100) - 125px); */
}

#robot-inspector-background {
  background-color: white;
  background-image: url("./Resources/images/background_robot_inspector.png");
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-position: calc(50% - 106px) top;
  height: 100%;
}

#weigh-station-background {
  background-color: white;
  background-image: url("./Resources/images/background_weigh_station.png");
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-position: center top;
  height: 100%;
}

/* #progressBar {
  margin-top: 5px;
  margin-bottom: 5px;
  min-width: 100vw;

}
#progressBar div {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
} */

#timer-bar-progress-bar {
  margin: 5px;
}

#timer-bar-progress-bar span {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.actionButtons {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #aaa;
  text-align: center;
  height: 115px;
  font-size: 16px;
}

.actionButtons .row {
  margin: 0;
  padding: 0;
}

.actionButtons a {
  display: block;
  padding: 0 0 5px 0;
  color: #fff;
  margin: 0;
}

.actionButtons h1 {
  color: #fff;
  padding: 0;
  margin: 0;
}

.redButton {
  background-color: #df3736;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 116px;
}

.blueButton {
  background-color: #4877bb;
  height: 116px;
}

.redButton h1,
.blueButton h1 {
  margin: 0;
  padding: 8px 0 0 0;
  line-height: 32px;
}

#robotInspector {
  margin: 0;
  padding: 0;
  height: calc(100% - 25px);
  border: 0;
}

#robotInspector tr,
#robotInspector td {
  border: 0;
}

#conveyor {
  background-image: url("./Resources/images/conveyor_belt.png");
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-position: center top;
  height: 10%;
}

#conveyer > td {
  padding: 0px;
}

.robot {
  width: 50%;
  vertical-align: bottom;
  padding: 0;
  position: relative;
  bottom: -9px;
}

.robot img {
  max-height: 250px;
  max-width: 100%;
  width: auto;
  height: 100%;
}

#leftRobot {
  text-align: right;
  padding-right: calc(1vh);
}

#rightRobot {
  text-align: left;
  padding-left: calc(1vh);
}

#controlBox td {
  text-align: center;
  vertical-align: top;
  margin: 0;
  padding: 0;
}

#controlBox img {
  height: 25%;
  width: auto;
  max-height: 125px;
  min-height: 125px;
}

#weigh-station-background {
  height: 100%;
  background-color: white;
  background-image: url("./Resources/images/background_weigh_station.png");
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-position: center top;
}

#word-images {
  margin: 0;
  padding: 0;
  height: calc(100% - 25px);
  border: 0;
}

#word-images tr,
#word-images td {
  border: 0;
}

.truck {
  background-size: auto 75%;
  background-repeat: no-repeat;
  height: 50%;
}

.truck-question {
  font-weight: bold;
  font-size: 7vh;
  width: 65%;
  text-align: center;
  position: relative;
  top: calc(-4vh);
}

.red-question {
  color: #df3736;
  margin: auto;
  left: calc(-8vh);
}

.blue-question {
  color: #4877bb;
  margin: auto;
  left: calc(8vh);
}

#red-truck {
  background-image: url("./Resources/images/truck_red.png");
  background-position: calc(50% - 20px) 50%;
}

#blue-truck {
  background-image: url("./Resources/images/truck_blue.png");
  background-position: calc(50% + 20px) 50%;
  text-align: right;
}

.feather {
  background-size: contain;
  background-repeat: no-repeat;
  height: 40%;
  background-position: center;
}

.feather-question {
  font-weight: bold;
  font-size: 5vh;
  text-align: center;
  position: relative;
  color: #fff;
}

#feather-background {
  height: 100%;
  background-color: #cbeff3;
}

#top-feather {
  background-image: url("./Resources/images/feather_top.png");
}

#bottom-feather {
  background-image: url("./Resources/images/feather_bottom.png");
}

#birds {
  background-size: auto 70%;
  background-image: url("./Resources/images/birds.png");
  background-position: center;
  background-repeat: no-repeat;
  height: 20%;
}

/* game-tutorial CSS */

.feedbackArrow {
  position: absolute;
  z-index: 1;
  max-height: 50px !important;
  bottom: 70px;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
}

#leftArrow {
  right: 100px;
  transform-origin: left;
}

#rightArrow {
  left: 100px;
  transform-origin: right;
}

.bounce-left {
  animation-name: bounce-left;
  animation-timing-function: linear;
}

.bounce-right {
  animation-name: bounce-right;
  animation-timing-function: linear;
}

@keyframes bounce-left {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes bounce-right {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.game-tutorial-instruction {
  padding: 1rem;
  text-align: left;
}

.game-tutorial-modal span {
  font-weight: bold;
}

.game-tutorial-icon-img {
  margin-right: 10px;
  width: 24px;
}

.custom-alert {
  background-color: #d9def4;
  border-color: #d9def4;
  color: #2e408a;
  margin-bottom: 0px;
}

.custom-modal-header-footer {
  display: block;
  border-bottom: none;
  border-top: none;
  background-color: white;
}

.tutorial-modal-header {
  padding: 0;
}

.tutorial-modal-footer {
  margin-bottom: 1rem;
}

.modal-max-width {
  max-width: 700px;
  top: calc(12vh);
}

.game-tutorial-modal {
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 15%);
  background-color: white;
}

.modal-position {
  top: calc(15vh);
}

.game-tutorial-start-complete {
  text-align: center;
}

.game-tutorial-modal h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.game-tutorial-feedback {
  padding: 1rem;
}

.game-tutorial-complete-modal-table {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d8d9d9;
  padding: 10px;
  margin-bottom: 0px;
}

.game-tutorial-complete-modal-table span {
  display: block;
}

.noPadding {
  padding: 0 0 0 0;
}

.noMargin {
  margin: 0 0 0 0;
}

.desktop-app-button,
.documents-button {
  width: 85px;
  border: none;
  display: block;
  margin: 0;
  padding: 0;
  font-size: 10px;
  height: 80px;
  text-transform: none;
  letter-spacing: normal;
  line-height: 1.3em;
  font-weight: normal;
  background: transparent;
}

.desktop-app-button:hover {
  -webkit-transform: unset;
  transform: unset;
}

.documents-button span {
  display: inline-block;
  margin: 3px;
}

#clik-v2-desktop .desktop-app-button:hover,
.desktop-app-button:focus {
  background-color: #4d5782;
  border-radius: 4px;
}

#clik-v2-desktop .documents-button:hover,
.documents-button:focus {
  background-color: #edeeee;
  border-radius: 4px;
}

.desktop-apps-container {
  background-color: #666666;
  border: 1px solid #666666;
}

.desktop-icon {
  color: #fff;
}

.open-app-icon-button {
  background: transparent;
  border: 0;
}

.open-app-icon {
  margin: 0 7px 0 7px;
}

.overlay-button {
  z-index: 100;
  position: absolute;
  top: 6px;
  right: 0;
  cursor: pointer;
  background: transparent;
  height: 15px;
  border: 0;
}

#window-repeater {
  width: -moz-calc(100% - 575px) !important;
  width: -webkit-calc(100% - 575px) !important;
  width: -o-calc(100% - 575px) !important;
  width: calc(100% - 575px) !important;
}

.clik-internet-tab {
  padding: 7px 75px 8px 7px;
  margin-right: 5px;
  margin-top: 10px;
  height: 40px;
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  background-color: #f2f2f2;
}

#word-document-content {
  border: 0;
}

#outlook-send-button {
  height: 80px;
  margin: 10px;
  padding: 0 10px;
  font-size: 0.9em;
}

.clik-window {
  -webkit-box-shadow: 3px 3px 5px 0px #333;
  -moz-box-shadow: 3px 3px 5px 0px #333;
  box-shadow: 3px 3px 5px 0px #333;
}

.outlook-tab-header {
  color: #ffffff;
  background-color: #2a579a;
  padding: 6px 12px;
  font-size: 0.8em;
}

.outlook-compose-label {
  width: 55px;
  display: inline-block;
  text-align: right;
  margin-bottom: 3px;
  margin-right: 3px;
  font-size: 0.8em;
}

input.outlook-compose-field {
  display: inline-block;
  width: 200px;
  font-size: 0.8em;
  margin-bottom: 3px;
  height: 28px;
}

.hover-shade:hover {
  background-color: #a8a8a8;
  opacity: 0.35;
}

.progress-bar-text {
  margin-left: 10px;
}

.mrab-container {
  padding: 0px;
}

.shapes-row {
  display: flex;
  flex-flow: row;
  justify-content: center;
  padding: 10px 0px;
  margin-top: 15px;
}

.shapes {
  margin-right: 20px;
}

.numbers-row {
  display: flex;
  flex-flow: row;
  justify-content: center;
  padding: 10px 0px;
}

.shapes-row-conjuction {
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin: 10px 20px 0 20px;
}

.test-display-container {
  display: flex;
}

.instruction-text {
  font-size: 20px;
  margin: auto;
}

.instruction-text-mobile {
  font-size: 16px;
  margin: auto;
}

.first-instruction-button-row {
  display: flex;
  justify-content: flex-end;
}

.instruction-button-row {
  display: flex;
  justify-content: space-between;
}

.center-screen,
.task-1-center-screen,
.task-2-center-screen,
.task-5-center-screen {
  width: 180px;
  height: 180px;
  margin: 200px auto 0 auto;
}

.task-3-center-screen,
.task-4-center-screen,
.task-6-center-screen {
  width: 350px;
  margin: auto;
  margin-top: 100px;
}

.task-7-center-screen,
.task-8-center-screen,
.task-9-center-screen {
  width: 400px;
  height: 100px;
  margin: 200px auto 0 auto;
}

.task-two-center-screen {
  width: 180px;
  height: 180px;
  margin: 250px auto 0 auto;
}

#countdown-number {
  font-size: 64px;
  font-weight: bold;
}

.instructions-shape {
  margin: 0 10px;
}

.instructions-keys {
  margin-top: -100px;
}

.container-spatial {
  width: 400px;
  height: 350px;
  border-style: solid;
  border-width: 4px;
  border-color: black;
  margin: 0 auto;
  position: relative;
}

#clik-v2-desktop {
  font-family: "Lato", sans-serif;
}

#clik-v2-desktop button {
  outline: 0;
}

#clik-v2-desktop .hover-shade:hover {
  background-color: #dcdcdc;
  opacity: 1;
}

#spatial-location1 {
  height: 80px;
  width: 80px;
  position: absolute;
  top: 1%;
  left: 1%;
  font-size: 84px;
  font-weight: bold;
  margin-top: 20px;
}

#spatial-location2 {
  height: 80px;
  width: 80px;
  position: absolute;
  top: 1%;
  right: 1%;
  font-size: 84px;
  font-weight: bold;
  margin-top: 20px;
}

#spatial-location3 {
  height: 80px;
  width: 80px;
  position: absolute;
  bottom: -20px;
  left: 1%;
  font-size: 84px;
  font-weight: bold;
}

#spatial-location4 {
  height: 80px;
  width: 80px;
  position: absolute;
  bottom: -20px;
  right: 1%;
  font-size: 84px;
  font-weight: bold;
}

.true_statement {
  font-size: 26px;
  font-weight: bold;
}

.three_term_text {
  font-size: 21px;
}

.task-eight-text {
  font-size: 36px;
  font-family: "Times New Roman", Times, serif;
}

.task-eight-text-test {
  font-size: 68px;
  letter-spacing: 15px;
  font-family: "Times New Roman", Times, serif;
}

#one,
#two {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  float: left;
  font-size: 64px;
  font-weight: bold;
  text-align: center;
  height: 80px;
  width: 80px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}

#eight,
#nine {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  float: right;
  font-size: 64px;
  font-weight: bold;
  text-align: center;
  height: 80px;
  width: 80px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}

#one-tablet,
#two-tablet {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  float: left;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  height: 60px;
  width: 60px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}

#eight-tablet,
#nine-tablet {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  float: right;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  height: 60px;
  width: 60px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}

.highlight {
  background-color: red;
}

.spinner-container {
  display: flex;
  height: 50vh;
  align-items: center;
  justify-content: center;
}

.spinner {
  margin: auto;
}

.button:focus,
.button:hover,
button:focus,
button:hover,
input[type="button"]:focus,
input[type="button"]:hover,
input[type="reset"]:focus,
input[type="reset"]:hover,
input[type="submit"]:focus,
input[type="submit"]:hover {
  transform: none;
  opacity: 0.75;
}

.button:focus,
button:focus,
input[type="button"]:focus {
  opacity: 1;
}

#clik-v2-desktop .button:focus,
.button:hover,
button:focus,
button:hover,
input[type="button"]:focus,
input[type="button"]:hover,
input[type="reset"]:focus,
input[type="reset"]:hover,
input[type="submit"]:focus,
input[type="submit"]:hover {
  opacity: 1;
}

.modal-body {
  background-color: #fff;
  padding: 0 15px;
}

.modal-help {
  max-width: 85vw;
  width: 85vw;
  overflow-y: auto;
  max-height: 85vh;
}

.footer {
  margin-top: 16px;
  font-size: 14px;
  color: "#000000";
}

.footer a {
  color: "black";
}

.button-link {
  margin: 0;
  padding: 0;
  text-transform: none;
  font-weight: normal;
  border: 0;
  color: #0f5897;
  font-size: 11px;
  letter-spacing: normal;
  background-color: transparent;
}

.button-link:hover {
  background-color: transparent;
  color: #0f5897;
  text-decoration: underline;
}

.button-warning {
  color: #111;
}

.button-success {
  background-color: #197c1d;
  border-color: #197c1d;
}

.button-primary {
  background-color: #0f5897;
  border-color: #0f5897;
}

ol ul {
  margin-left: 20px;
}

.testtaker-telephone-form {
  margin-top: 30px;
}

#internet-address-input {
  width: -moz-calc(100% - 192px) !important;
  width: -webkit-calc(100% - 192px) !important;
  width: -o-calc(100% - 192px) !important;
  width: calc(100% - 192px) !important;
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}

.upload-button {
  background-color: #fff;
  color: black;
  border-color: #b1b3b3;
  display: inline-block;
  height: 40px;
  padding: 0 20px;
  width: auto;
  overflow: visible;
  -webkit-appearance: none;
  transition: all 0.1s ease-out;
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  display: none;
}

.red {
  color: red;
}

#number span {
  font-size: 2.5em;
}

.mcAnswerOption {
  margin-bottom: 5px;
}

.mcAnswerOption label {
  display: block;
  border: 1px solid #888;
  padding: 8px 5px;
  border-radius: 3px;
  cursor: pointer;
}

.mcAnswerOption label:hover {
  border-color: black;
}

.accommodations-text {
  font-size: 20px;
}

.modal-accommodations {
  max-height: 100vh;
  overflow-y: auto;
}

#wir-container {
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 999999;
  height: 100%;
}

/* Ripple effect */

.ripple {
  background-position: center;
  transition: background 0.8s;
}

.ripple:hover {
  background: rgba(0, 0, 0, 0.1)
    radial-gradient(circle, transparent 1%, rgba(0, 0, 0, 0.1) 1%) center/15000%;
}

.ripple:active {
  background-color: rgba(0, 0, 0, 0.1);
  background-size: 100%;
  transition: background 0s;
}

/*
 *  MEDIA QUERIES
 */

/* Iphone 6, 7, and 8, in landscape mode */

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
  #game-test {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 220px;
  }
  .robot {
    height: 130px;
    width: 50%;
  }
  #controlBox img {
    height: 15%;
    width: auto;
    max-height: 50px;
    min-height: 50px;
  }
  .img-responsive {
    min-width: 100px;
    max-width: 600px;
    height: auto;
  }
  .img-responsive95 {
    min-height: auto;
  }
  #robot-feedback-modal-position {
    top: 170px;
  }
  .game-tutorial-feedback {
    padding: 0.4rem;
  }
  .feedbackArrow {
    bottom: 30px;
  }
  .modal-max-width {
    max-width: 500px;
    margin: 0 auto;
  }
}

/* iPhone 6, 7 and 8, in Portrait mode */

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
  #game-test {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    /* height: 400px; */
  }
  @supports (-webkit-touch-callout: none) {
    #game-test {
      height: calc(100vh - 195px);
    }
  }
  .img-responsive {
    min-width: 100px;
    max-width: 340px;
    height: auto;
  }
  .img-responsive95 {
    min-height: auto;
  }
  .redButton h1,
  .blueButton h1 {
    padding-top: 20px;
  }
  .actionButtons a:hover {
    text-decoration: none;
  }
  #one,
  #two {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    float: left;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    height: 60px;
    width: 60px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
  }
  #eight,
  #nine {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    float: right;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    height: 60px;
    width: 60px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
  }
  .modal-position {
    top: 0;
  }
  .modal-max-width {
    top: 0;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 768px) and (orientation: portrait) {
  .redButton h1,
  .blueButton h1 {
    padding-top: 20px;
  }
  .welcome-page-container {
    height: 100vh;
  }
}

/* iPad ( all versions ) and iPad mini, in portrait mode */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .img-responsive {
    min-height: 100px;
    max-height: calc(65vh - 475px);
    max-width: 100%;
    width: auto;
  }
  .img-responsive95 {
    min-height: auto;
  }
}

@media (min-width: 768px) {
  .display-768-up {
    display: "";
  }
  .display-767-down {
    display: none;
  }
}

@media (max-width: 767px) {
  .display-767-down {
    display: "";
  }
  .display-768-up {
    display: none;
  }
}

/* iPad Pro in Portrait mode */

@media only screen and (min-device-width: 1024px) and (orientation: portrait) {
  .img-responsive {
    min-height: 100px;
    max-height: calc(90vh - 475px);
    max-width: 100%;
    width: auto;
  }
  .img-responsive95 {
    min-height: auto;
  }
}

.button-less-padding {
  padding: 0 10px;
}

.caret-off::before {
  display: none;
}

.caret-off::after {
  display: none;
}

#help-dropdown {
  background: white;
  border: none;
}

.tech-support-text {
  padding: 15px 1.25em;
}

.tech-support-text h6 {
  font-size: 14px;
  font-weight: 500;
}

.tech-support-text p {
  font-size: 14px;
}

.tech-support-text a {
  font-size: 14px;
}

.tech-support-text #need-help {
  font-size: 20px;
  font-weight: 600;
}

.upload-button {
  width: 100% !important;
}

.upload-resume-btn-wrapper {
  width: 100% !important;
}

.remove-upload-icon {
  background: "white";
  border: none;
  padding: 0px;
  height: 0px;
}

.test-header {
  height: 92px;
  background: #e7e8e8 url(./Resources/images/testmaker_background_small.png)
    center right no-repeat;
}

.aptitude-test-header {
  background: #ffedcc url(./Resources/images/aptitude_background_small.png)
    center right no-repeat;
}

.personality-test-header {
  background: #d5f5f6 url(./Resources/images/personality_background_small.png)
    center right no-repeat;
}

.skills-test-header {
  background: #d9def4 url(./Resources/images/skills_background_small.png) center
    right no-repeat;
}

.emotional-test-header {
  background: #ffded7 url(./Resources/images/emotional_background_small.png)
    center right no-repeat;
}

.risk-test-header {
  background: #f7ccdd url(./Resources/images/risk_background_small.png) center
    right no-repeat;
}

.oda-card {
  min-height: 500px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

/* needed for the new event id page so that it matches the UI docs */
.oda-card-new-eventid-page {
  min-height: 450px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.dataLists {
  width: 100%;
}

.ensure-compliance-text {
  font-size: 12px;
  color: var(--color-secondary-text);
  margin-top: -10px;
  padding-top: 0px;
  line-height: 13px;
}

label.selected-answer {
  border: 1px solid #425cc7;
  background-color: #eceef9;
  border-radius: 3px;
}

#app.workplace-insights {
  background-color: #fff;
}

#app.workplace-insights #header {
  padding: 0 16px;
}

.btn-outline-primary {
  border-color: #425cc7;
  color: #425cc7;
  opacity: 1;
}

.btn-outline-primary:hover {
  background-color: #546ccc;
  border-color: #546ccc;
  opacity: 1;
}

.btn-outline-primary:disabled,
.btn-outline-primary-disabled {
  background-color: #c6ceee;
  border-color: #c6ceee;
  color: #425cc7;
  opacity: 1;
}

.non-welcome-logo {
  max-height: 40px;
  width: auto;
}

.instruction-graphic-img {
  padding: 16px;
  width: 112px;
  height: 112px;
}

.link-results-header {
  font-family: Arial;
  font-size: 21px;
  font-weight: 800;
  line-height: 1.43;
  text-align: center;
  color: "black";
}

.link-results-text {
  font-family: Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: "black";
}

.error-message {
  font-family: Lato;
  font-size: 12px;
  line-height: 1.5;
  color: #d50057;
}

.join-research-button {
  height: 40px;
  color: black;
  font-size: 14px;
  background-color: darkgray;
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: bold;
}

#help-dropdown + .dropdown-menu .dropdown-item {
  white-space: break-spaces;
}

#reuse-results-bold {
  font-weight: bold;
}
/* clik v2*/

.outlook-menu-bars {
  border: none;
  color: #005a9e;
  margin-right: 10px;
  background-color: #f7f7f7;
}

.outlook-action-button {
  background-color: #2dccd3;
  border-radius: 2px;
  color: white;
  border: none;
  padding: 6px 8px;
}

.clik-window button {
  border: none;
}

.outlook-nav {
  padding-top: 5px;
  text-align: right;
}

.outlook-nav button {
  background-color: #f7f7f7;
}

.outlook-nav svg {
  margin-right: 5px;
  color: #001f4e;
}

.outlook-sidebar-menu svg {
  margin-left: 10px;
  margin-right: 22px;
}

li#Documents::before {
  width: 38px;
  height: 24px;
  content: "";
  display: block;
  background-color: #d9def4;
  position: absolute;
  left: 0px;
}

#chat-notification {
  border: none;
  position: absolute;
  right: -300px;
  bottom: 10px;
  padding: 0px;
  animation: movein 0.5s ease forwards;
}

@keyframes movein {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 10px;
    opacity: 1;
  }
}

.complete-tutorial-modal-instructions span {
  font-weight: bold;
}

.video-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: fill;
  display: inline-block;
}
